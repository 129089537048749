import {Application, Assets, Sprite, Graphics, Container} from "pixi.js";
import {useCallback, useEffect, useRef} from "react";
import classNames from 'classnames';
import styles from './Battle.module.css';

import useStorage from './useStorage';

export default function Battlefield (props) {
  const { className } = props;
  const { checkRoom, mm, startFindRoom, mmSide } = useStorage();

  const onBattleEnd = useCallback(() => {
    checkRoom();
  });

  useEffect(function() {
    checkRoom();
  }, []);

  useEffect(function() {
    if (mm === 'waiting') {
      let intervalId = setInterval(() => {
        startFindRoom();
      }, 3000);
      return () => clearInterval(intervalId);
    }
  }, [mm]);

  const cn = classNames(className, styles.main);

  if (mm === null) {
    return (<div className={classNames(cn, styles.waiting)}>Preparing</div>)
  } else if (mm === '') {
    return (<div className={classNames(cn, styles.preparing)}><button onClick={() => startFindRoom()}>Find game</button></div>)
  } else if (mm === 'waiting') {
    return (<div className={classNames(cn, styles.waiting)}>Waiting opponent</div>)
  } else {
    return (<div className={classNames(cn, styles.battle)}>
      <BattleCanvas mm={mm} onBattleEnd={onBattleEnd}/>
    </div>)
  }
}

function BattleCanvas(props) {
  const refObject = useRef();
  const {mm, onBattleEnd} = props;

  useEffect(function () {
    if (!mm || mm === 'waiting') return;

    (async function () {
      const battlefieldScene = new BattlefieldScene(refObject.current, onBattleEnd);
      battlefieldScene.initialize(mm);
    })();
  }, [mm]);

  return (<div style={{flex: 1}} ref={refObject}/>);
}

class BattlefieldScene {
  constructor(element, onEndCallback) {
    this.element = element;
    this.onEndCallback = onEndCallback;
    this.application = new Application();
    window.__PIXI_APP__ = BattlefieldScene.app = this.application;
  }

  async initialize(connectionUrl) {
    const ratio = global.ratio;
    const width = this.element.clientWidth;
    const height = this.element.clientHeight;

    const widthRatio = width * ratio;
    const heightRatio = height * ratio;
    
    await this.application.init({ background: 0xffffff, width: widthRatio, height: heightRatio });
    this.application.canvas.style.width = width + 'px';
    this.application.canvas.style.height = height + 'px';
    this.application.stage.eventMode = "static";
    this.application.stage.hitArea = this.application.screen;

    this.element.appendChild(this.application.canvas);

    const gridContainer = new Container();
    this.grid = new BattlefieldGrid(gridContainer, widthRatio, heightRatio);
    this.application.stage.addChild(gridContainer);

    this.battlefieldSocket = new BattlefieldSocket();
    await this.battlefieldSocket.initialize(connectionUrl, this.onMessage.bind(this), this.onEndCallback);

    this.battlefieldCards = new BattlefieldCards(this.application.stage, '#d67679', (a, b) => {this.battlefieldSocket.playСard(parseInt(a), this.grid.invertCoordinate(this.grid.getCoordinate(b)))});
    this.battlefieldCards.setPosition({ x: 0, y: heightRatio - global.ratio * 64 });

    this.battlefieldUnits = new BattlefieldUnits(gridContainer, this.grid);
  }

  onMessage(msg) {
    if (msg.t == 'playerFrame') {
      for (const key in msg.b.myCards) {
        this.battlefieldCards.updateCard(key, msg.b.myCards[key]);
      }
      this.battlefieldUnits.setUnits(msg.b.enemies);
    } else if (msg.t == 'handshake') {
      this.grid.update(msg.b.grid);
    }
  }
}

class BattlefieldSocket {
  async initialize(url, onMessage, onEndCallback) {
    return new Promise((resolve, reject) => {
      console.log('[BattleField] Connect ' + url);
      this.socket = new WebSocket(url);

      this.socket.onopen = resolve;
      this.socket.onerror = reject;

      this.socket.onmessage = (event) => {
        let data = JSON.parse(event.data);
        console.log('[BattleField] Message', data);
        onMessage(data);
      };
    
      this.socket.onclose = function(event) {
          if (event.wasClean) {
              console.log(`[BattleField] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`);
          } else {
              console.log('[BattleField] Соединение прервано');
          }
          onEndCallback();
      };    
    });
  }

  playСard(id, point) {
    let data = {t: 'PlayCard',  b: { id, point }};
    console.log('[BattleField] Send', data);
    this.socket.send(JSON.stringify(data));
  }
}

class BattlefieldUnits {
  constructor(parent, grid) {
    this.element = new Container();
    this.grid = grid;

    parent.addChild(this.element);
  }

  units = {}
  setUnits(units) {
    for (const key in units) {
      const unit = units[key];
      if (!this.units[key]) this.createUnit(key, unit);
      this.updateUnit(key, unit)
    }

    for (const key in this.units) {
      if (!units[key]) this.removeUnit(key);
    }
  }

  createUnit(key, unit) {
    this.units[key] = new BattlefieldUnit(this, this.element, unit, this.grid, unit.isMy ? '#d67679' : '#7478d4');
  }
  updateUnit(key, unit) {
    this.units[key].update(unit);
  }
  removeUnit(key) {
    this.units[key].remove();
    delete this.units[key];
  }
  getUnit(key) {
    return this.units[key];
  }
}

class BattlefieldUnit {
  constructor(units, parent, unit, grid, color) {
    this.units = units;
    this.parent = parent;
    this.element = new Container();
    this.attackTargetCircle = null;
    this.attackTargetLine = null;
    this.way = new Graphics();
    this.color = color;
    this.size = unit.size;
    this.grid = grid;
    this.createUnit(unit.name, color);
    this.hp = new BattleUnitHp(this.element, this.grid.getSize(), this.size, unit.hp.max, unit.hp.current);
    this.parent.addChild(this.element);
    this.parent.addChild(this.way);
    this.prevAttackTarget = null;
  }

  update(unit) {
    this.hp.update(unit.hp.max, unit.hp.current);
    var position = this.grid.getPosition(unit.position.x, unit.position.y)
    this.element.x = position.x;
    this.element.y = position.y;
    this.way.clear();
    if (unit.way.length > 0) {
      this.way.moveTo(position.x + this.grid.getSize() / 2, position.y + this.grid.getSize() / 2);
      for(var i = 0; i < unit.way.length; i++) {
        let pos = this.grid.getPosition(unit.way[i].x, unit.way[i].y);
        pos.x += this.grid.getSize() / 2;
        pos.y += this.grid.getSize() / 2;
        this.way.lineTo(pos.x, pos.y);
      }
      this.way.stroke({ width: 2, color: 0x000000 });
    }
    if (unit.attackTarget) {
      let target = this.units.getUnit(unit.attackTarget);
      if (this.prevAttackTarget != target) {
        this.prevAttackTarget = target;
        this.clearAttackIndicator();

        let gridSize = this.grid.getSize();
        let circleSize = target.size + 2;
        let center = gridSize * target.size / 2;
        this.attackTargetCircle = new Graphics().circle(center, center, gridSize / 2 * circleSize).stroke({ color: 0xff0000, width: 4, alignment: 1 });

        this.attackTargetLine = new Graphics();
        this.attackTargetLine.moveTo(this.element.x + gridSize / 2, this.element.y + gridSize / 2);
        this.attackTargetLine.lineTo(target.element.x + target.size / 2 * gridSize, target.element.y + target.size / 2 * gridSize);
        this.attackTargetLine.stroke({ width: 4, color: 0xff0000 });
        this.parent.addChild(this.attackTargetLine);
        target.element.addChild(this.attackTargetCircle);
      }
    } else {
      this.clearAttackIndicator();
    }
  }

  remove() {
    this.parent.removeChild(this.element);
    this.parent.removeChild(this.way);
    this.clearAttackIndicator();
  }

  clearAttackIndicator() {
    if (this.attackTargetCircle) {
      this.attackTargetCircle.destroy();
      this.attackTargetCircle = null;
    }
    if (this.attackTargetLine) {
      this.attackTargetLine.destroy();
      this.attackTargetLine = null;
    }
  }

  createUnit(name) {
    switch (name) {
      case 'head_quarter': return new TronCard(this.element, this.color, this.grid.getSize(), this.size);
      case 'small_tower': return new TronCard(this.element, this.color, this.grid.getSize(), this.size);
      case 'trooper': return new WarriorCard(this.element, this.color, this.grid.getSize(), this.size);
      case 'shotguner': return new ShooterCard(this.element, this.color, this.grid.getSize(), this.size);
      default: throw new Error('Unknown enemy name ' + name);
    }
  }
}

class BattlefieldCards {
  constructor(parent, color, onClick) {
    this.element = new Container();
    this.color = color;
    this.onClick = onClick;
    parent.addChild(this.element);

    this.onDragStartBind = this.onDragStart.bind(this);
    this.onDragMoveBind = this.onDragMove.bind(this);
    this.onDragEndBind = this.onDragEnd.bind(this);

    BattlefieldScene.app.stage.on('pointerup', this.onDragEndBind);
    BattlefieldScene.app.stage.on('pointerupoutside', this.onDragEndBind);
  }
  
  cards = {}
  updateCard(key, card) {
    if (this.cards[key]) return;
    this.cards[key] = new BattlefieldCard(this.element, card.name, this.color);
    this.cards[key].setPosition({ x: Object.keys(this.cards).length * 64 * global.ratio, y: -32 * global.ratio });
    this.cards[key].sprite.on('pointerdown', () => this.onDragStartBind(key, card.name, this.color));
  }
  
  setPosition({ x, y }) {
    this.element.x = x;
    this.element.y = y;
  }

  onDragMove(event)
  {
      if (this.dragTarget)
      {
        this.dragTarget.element.parent.toLocal({x: event.global.x - 16, y: event.global.y - 16}, null, this.dragTarget.element.position);
      }
  }

  onDragStart(key, name, color)
  {
      var {element} = createCard(name, BattlefieldScene.app.stage, color);
      this.dragTarget = {key, element};
      BattlefieldScene.app.stage.on('pointermove', this.onDragMoveBind);
  }

  onDragEnd(event)
  {
      if (this.dragTarget)
      {
        BattlefieldScene.app.stage.removeChild(this.dragTarget.element)
        BattlefieldScene.app.stage.off('pointermove', this.onDragMoveBind);
        this.onClick(this.dragTarget.key, event.global)
        this.dragTarget = null;
      }
  }
}

class BattlefieldCard {
  constructor(parent, name, color) {
    this.name = name;
    this.color = color;
    this.element = new Container();
    const craphics = new Graphics();
    craphics.rect(0, 0, 64 * global.ratio, 64 * global.ratio).fill(0xffffff).stroke({ color: 0x000000, width: 2, alignment: 1 });
    this.element.addChild(craphics);

    const createdCard = createCard(name, this.element, color);
    createdCard.setPosition({x: 16 * global.ratio, y: 16 * global.ratio});

    this.sprite = new Sprite();
    this.sprite.width = 64 * global.ratio;
    this.sprite.height = 64 * global.ratio;
    this.sprite.eventMode = 'static';
    this.sprite.cursor = 'pointer';

    this.element.addChild(this.sprite);

    parent.addChild(this.element);
  }

  setPosition({ x, y }) {
    this.element.x = x;
    this.element.y = y;
  }
}

function createCard(name, craphics, color) {
  switch (name) {
    case 'trooper': return new WarriorCard(craphics, color, 32 * global.ratio, 1);
    case 'shotguner': return new ShooterCard(craphics, color, 32 * global.ratio, 1);
    default: throw new Error('Unknown card name ' + name);
  }
}

class TronCard {
  constructor(parent, color, gridSize, size) {
    this.element = new Graphics().rect(0, 0, gridSize * size, gridSize * size).fill(color);
    parent.addChild(this.element)
  }
  setPosition({ x, y }) {
    this.element.x = x;
    this.element.y = y;
  }
}

class WarriorCard {
  constructor(parent, color, gridSize, size) {
    this.element = new Graphics().circle(gridSize * size / 2, gridSize * size / 2, gridSize / 2 * size).fill(color).stroke({ color: 0xff0000, width: 1, alignment: 1 });
    parent.addChild(this.element)
  }
  setPosition({ x, y }) {
    this.element.x = x;
    this.element.y = y;
  }
}

class ShooterCard {
  constructor(parent, color, gridSize, size) {
    this.element = new Graphics().circle(gridSize * size / 2, gridSize * size / 2, gridSize / 2 * size).fill(color).stroke({ color: 0x000000, width: 1, alignment: 1 });
    parent.addChild(this.element)
  }
  setPosition({ x, y }) {
    this.element.x = x;
    this.element.y = y;
  }
}

class BattlefieldGrid {
  constructor(parent, width, height) {
    this.gridColumns = 64;
    this.gridRows = 36;
    const gridOriginalSize = 32;

    const gridOriginalWidth = gridOriginalSize * this.gridRows;
    const gridOriginalHeight = gridOriginalSize * this.gridColumns;
    const a = width / gridOriginalWidth
    const b = height / gridOriginalHeight
    const c = Math.min(a, b);

    const gridWidth = gridOriginalWidth * c;
    const gridHeight = gridOriginalHeight * c;

    this.gridSize = gridOriginalSize * c;

    this.parent = parent;
    this.parent.position = { x: (width - gridWidth) / 2, y: (height- gridHeight) / 2 };

    const graphics = new Graphics();
    parent.addChild(graphics);
    this.graphics = graphics;
    this.update([]);
  }

  getSize() {
    return this.gridSize;
  }

  invertCoordinate({x, y}) {
    return { 
      x: this.gridRows - (x + 1), 
      y: this.gridColumns - (y + 1)
    };
  }

  getCoordinate({x, y}) {
    return { 
      x: Math.floor((x - this.parent.position.x) / this.gridSize), 
      y: Math.floor((y - this.parent.position.y) / this.gridSize)
    };
  }

  getPosition(row, column) {
    return { 
      x: this.gridSize * row, 
      y: this.gridSize * column
    };
  }

  update(grid) {
    for (let column = 0; column < this.gridColumns; column++) {
      for (let row = 0; row < this.gridRows; row++) {
        var position = this.getPosition(row, column);
        this.graphics.rect(position.x, position.y, this.gridSize, this.gridSize);
        if (grid[column] && grid[column][row] == 0) {
          this.graphics.fill(0x222222);
        } else if (grid[column] && grid[column][row] == 1) {
          this.graphics.fill(0xffffff);
        } else {
          this.graphics.fill(0xaaaaaa);
        }
        this.graphics.stroke({ color: 0xf0f0f0, width: 1, alignment: 1 })
      } 
    }
  }
}

class BattleUnitHp {
  constructor(parent, gridSize, size, max, current) {
    this.element = new Container();
    this.size = size;
    this.current = current;
    this.max = max;
    this.container = new Container();
    this.progressBackground = new Graphics().rect(0, 0, gridSize * 2, 5).fill(0x000000);
    this.progressForeground = new Graphics().rect(0, 0, gridSize * 2, 5).fill(0x00ff00);
    this.element.addChild(this.container);
    this.container.addChild(this.progressBackground);
    this.container.addChild(this.progressForeground);
    this.container.position.x = gridSize * (size - 2) / 2;
    this.container.position.y =  -gridSize / 3;
    parent.addChild(this.element);
    this.draw();
  }

  update(max, current) {
    this.max = max;
    this.current = current;
    this.draw();
  }

  draw() {
    this.progressForeground.scale.x = this.current / this.max;
  }
}