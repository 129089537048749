import { useEffect, useState } from "react";

import useStorage from './useStorage';
import Battlefield from './battlefield-scene';
import styles from './Game.module.css';

function Game() {
  const { loaded, loadFailed, profile, bootstrap } = useStorage();

  useEffect(function () {
    if (!window.Telegram) return;
    bootstrap();
  }, []);

  if (!loaded)
    return <div>Loading...</div>;
  if (loaded && loadFailed)
    return <div>Failed: {loadFailed}</div>;

  return (
    <div className={styles.game}>
      <div className={styles.header}>Profile: {profile.id}/{profile.name}</div>
      <Battlefield className={styles.body} />
    </div>
  );
}

export default Game;
