import { useEffect, useState } from "react";
import Game from "./Game";

import styles from "./App.module.css";

function App() {
  let [isUserSelecting, setUserSelecting] = useState(true);

  useEffect(() => {
    if (window.Telegram.WebApp.initData) {
      setUserSelecting(false);
    }
  }, []);

  let selectUser = (num) => { 
    if (num === 1){
      global.INIT_DATA = process.env.REACT_APP_DEFAULT_INIT_DATA1;
      setUserSelecting(false);
    } else if (num === 2) {
      global.INIT_DATA = process.env.REACT_APP_DEFAULT_INIT_DATA2;
      setUserSelecting(false);
    }
  };

  if (isUserSelecting) {
    return (<div className={styles.welcome}><button onClick={() => selectUser(1)}>Use User1</button><button onClick={() => selectUser(2)}>Use User2</button></div>)
  }

  return (
    <Game/>
  );
}

export default App;
