import axiosClient from "axios";

let baseUrl = null;
if (window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost')
    baseUrl = 'http://localhost:50051';
else
    baseUrl = `${window.location.protocol}//api.${window.location.hostname}`;

const instance = axiosClient.create({
    baseURL: baseUrl,
    headers: {},
    withCredentials: false,
});

instance.interceptors.request.use(function (config) {
    if (window.ACCESS_TOKEN && !config.params?.access_token) {
        config.params = {...(config.params || {}), access_token: window.ACCESS_TOKEN};
    }
    if (typeof config.data == 'string')
    {
        config.headers['Content-Type'] = 'text/html; charset=utf-8';
    } else {
        config.headers['Content-Type'] = 'application/json; charset=utf-8';
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});


instance.interceptors.response.use(
    (res) => res.data,
    (err) => {
        if (err.response) {
            return Promise.reject(err.response.data);
        }

        return Promise.reject(err.message);
    }
);

const axios = (cfg) => instance.request(cfg);

export default axios;
