import { create } from "zustand";
import axios from "axios";

import http from './http';
import i18n from './i18n';

export default create(function (set, get) {
    return {
        loaded: false,
        loadFailed: false,
        profile: null,
        
        bootstrap: async function () {
            const en = await http({method: 'get',  url: '/localization/en'});
            i18n.addResourceBundle('en', 'translation', en, true, true);
            window.ACCESS_TOKEN = (await http({method: 'post', 'url': '/auth', data: window.Telegram.WebApp.initData || global.INIT_DATA})).access_token;
            await http({method: 'get', url: '/user/me'}).then(function(profile) {
                set({ profile });
            }).catch(function() {
                set({ loadFailed: true });
            }).finally(function() {
                set({ loaded: true });
            });
        },
        mm: null,
        mmSide: null,
        startFindRoom: async function() {
            try {
                let response = await http({method: 'post', url: '/mm/start'});
                if (response.createdAt) {
                    set({mm: 'waiting'});
                } else if (response.connectionUrl) {
                    set({mm: response.connectionUrl, mmSide: response.side});
                } else {
                    set({mm: '', mmSide: null});
                }
            } catch (error) {
                console.error(error);
            }
        },
        checkRoom: async function() {
            try {
                const response = await http({method: 'get', url: '/mm/status'});
                if (response.createdAt) {
                    set({mm: 'waiting'});
                } else if (response.connectionUrl) {
                    set({mm: response.connectionUrl, mmSide: response.side});
                } else {
                    set({mm: '', mmSide: null});
                }
            } catch (error) {
                console.error(error);
            }
        }
    }
});

// class Battlefield {
//     constructor(id, ownTeam, enemyTeam) {
//         this.ownTeam = ownTeam;
//         this.enemyTeam = enemyTeam;
//         this.connect(id);
//     }

//     connect(id) {
//         let socket = new WebSocket(`ws://localhost:4000/ws?id=${id}`);

//         socket.onopen = function(e) {
//             console.log("[BattleField] Соединение установлено");
//         };
    
//         socket.onmessage = (event) => {
//             const msg = JSON.parse(event.data);

//             if (event.data.isComplete) {
//                 console.log('[BattleField] Complete');
//                 return;
//             }

//             for (let key in msg.myCards) {
//                 this.updateCard(key, msg.myCards[key]);
//             }

//             for (let key in msg.enemies) {
//             const msg = JSON.parse(event.data);
//                 this.updateEnemy(msg.tick, key, msg.enemies[key]);
//             }
//         };
    
//         socket.onclose = function(event) {
//             if (event.wasClean) {
//                 console.log(`[BattleField] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`);
//             } else {
//                 console.log('[BattleField] Соединение прервано');
//             }
//         };
    
//         socket.onerror = function(error) {
//             console.log("Ошибка " + error.message);
//         };
//     }

//     // cards = {}
//     updateCard(key, card) {
//         // if (!cards[key]) {
//         //     console.log(`[updateCard] Create card ${key}`);
//         //     cards[key] = card;
//         // }

//         // console.log(`[updateCard] Update position ${card.position} card ${key}`);
//     }

//     updateEnemy(tick, key, enemy) {
//         if (enemy.isMy) this.ownTeam.updateEnemy(tick, key, enemy);
//         else this.enemyTeam.updateEnemy(tick, key, enemy);
//     }
// }

// class MutedBattleTeam {
//     updateEnemy(key, enemy) {}
// }

// class BattleTeam {
//     enemies = {};
//     createEnemy(tick, key, enemy) {
//         console.log(`[BattleTeam][${tick}] Create enemy ${key}`);
//         this.enemies[key] = enemy;
//     }
//     updateEnemy(tick, key, enemy) {
//         if (!this.enemies[key]) this.createEnemy(tick, key, enemy);
//         console.log(`[BattleTeam][${tick}] Update position (x: ${enemy.position.x}, y: ${enemy.position.y}) enemy ${key}`);
//     }
// }

// function delay(ms) {
//     return new Promise(resolve => setTimeout(resolve, ms));
// }